.apikeylist {
  // margin: 12px 0 0 12px;
  th:nth-child(2) {
    span {
      cursor: default;
    }
  }
}
.apikeyCheckboxWrapper {
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  span {
    margin-bottom: 12px;
  }
  input {
    border-color: rgba(0, 0, 0, 0.38);
  }
}
.apiAddKeyForm {
  width: 1020px;
}
.apiAddKeyFormLeft {
  width: 1020px;
  right: 180px;
}
.apiAddKeyCidrActions{
  margin-top: 16px;
}
.apiAddKeyCidrActions,
.methodListActions {
  margin-bottom: 20px;
  button {
    margin-right: 20px;
  }
}
.apiKeyCustomSearch {
  width: 600px;
  margin: 16px 0;
}
.formContent {
  // width: 800px;
  padding-top: 4px;
}
.apiInputName {
  max-width: 380px;
}
.apiDescriptionField {
  max-width: 680px;
}
.restrictions {
  display: flex;
  gap: 0 44px;
  align-items: center;
}
.restrictionsDisable {
  color: #c4c4c4;
  // margin: 0 44px 0 44px;
}
.titleTabMain,
.titleTabInfo {
  margin: 8px 0 20px 0;
  color: #7f7f7f;
  max-width: 680px;
  span {
    padding-bottom: 4px;
    // border-bottom: 1px solid #b9b9b9;
  }
}
.titleTabInfo {
  span {
    font-style: italic;
  }
}
.titleDescriptionList {
  margin-top: 20px;
  padding-left: 4px;
  color: #7f7f7f;
}
.descriptionTitleContent {
  // margin-top: 12px;
  // font-size: 20px;
  font-size: 22px;
  margin: 24px 0;
  color: #555;
  font-weight: 600;
}
.apikeyValueContent {
  padding: 8px;
  margin-top: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0 8px;
  span:nth-child(2) {
    font-weight: bold;
  }
}
.apiKeyValueLockBlock {
  display: flex;
  align-items: center;
  // width: 420px;
  width: 100%;
}
.apiKeyValueLockField {
  // width: 290px;
  // width: 100%;
}
.apiKeyValueLockBtn {
  background-color: white;
}

.whiteListFilterLine{
  // margin-top: 12px;
  display: flex;
  align-items: center;
  :global(.k-filter){
    margin-right: 12px;
  };
  :global(.whitelist-filter){
    max-width: 400px;
  }
}
.resultCheckIp {
  color: #f31700;
  font-style: italic;
  margin: 8px 0 0 0;
}
:global(.form-block){
  label{
    display: inline-block;
    margin-bottom: 8px;
  }
}
.latestAssetsCountInWlWrap{
  display: flex;
  justify-content: space-between;
}
