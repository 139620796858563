.task_type {
  color: white;
  border-radius: 4px;
  width: 32px;
  min-width: 32px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  &.audit {
    background-color: #039511;
  }
  &.audit_image {
    background-color: #0d7817;
  }
  &.agent_update {
    background-color: #3f51b5;
  }
  &.inventorization {
    background-color: #02a7f0;
  }
  &.package_update {
    background-color: #794cf5;
  }
  &.group_report {
    background-color: #ff9800;
  }
  &.single_report {
    background-color: #ffc107;
  }
  &.notification {
    background-color: #37caae;
  }
  &.bulletin_specific_analysis {
    background-color: #02a7f0;;
  }
  &.kb_install {
    background-color: #794cf5;
  }
  &.asset_ldap_synchronize {
    background-color: #085677;
  }
  &.windows_software_management {
    background-color: #794cf5;
  }
  &.system_reboot{
    background-color: #808080;
  }
}

.user_role {
  color: white;
  border-radius: 4px;
  width: 32px;
  text-align: center;
  display: inline-block;
  font-size: 14px;
  &.super_admin {
    background-color: #111111;
  }
  &.account_admin {
    background-color: #575151;
  }
  &.user {
    background-color: #818181;
  }
  &.reader_user {
    background-color: #a7a7a7;
  }

}
