// @import "./src/vars";

.scan_type {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
}

// .asset{
//   padding-left: 32px;
//   .k-filter-container{
//     margin-bottom: 0;
//   }
//   .k-grid .k-grid-container {
//     overflow: visible;
//     z-index: 9999;
//   }
//   .k-grid-content{
//     overflow: visible;
//   }
//     &__topactions{
//       border-bottom: 1px solid #ccc;
//       .k-i-plus{
//         color: #757474;
//       }
//       display: flex;
//       align-items: center;
//     }
//     &__score{
//       width: 32px;
//       padding: 4px;
//       font-size: 14px;
//     }
//     .k-button-group{
//       display: none;
//     }
//     .k-widget .k-toolbar{
//       span:nth-child(3) button{
//         display: none;
//       }
//     }
//   .k-grid{
//     padding-left: 0;
//     th:last-child, td:last-child{
//       width: 40px;
//       position: relative;
//       overflow: visible;
//     }
//   }
//   &__status{
//     .processing{
//       max-width: 112px;
//       text-align: center;
//       background-color: #1657cf;
//       box-sizing: border-box;
//       color: #fff;
//       padding: 4px 8px;
//       margin-bottom: 4px;
//       font-weight: 700;
//     }
//   }
//   &__assetscope{
//     @extend %assets-block;
//   }
//   &__menu{
//     .dropdown__container {
//       cursor: pointer;
//       display: inline-block;
//       .dropdown__content{
//         display: none;
//         width: 120px;
//         top: 0;
//         right: 40px;
//         padding: 4px;
//         .k-button {
//           font-size: 12px;
//           padding-bottom: 4px;
//         }
//       }
//     }
//     .dropdown__container:hover .dropdown__content{
//       display: block;
//       z-index: 19999;
//     }
//   }
//   .red{
//     background-color: #d9001b;
//     color: #fff;
//     font-weight: 700;
//   }
//   .yellow{
//     background-color: #dc851f;
//     color: #fff;
//     font-weight: 700;
//   }
// }
// .k-window-content {
//   padding: 0;
// }
// .host-inv-confirm{
//   margin-top: 42px;
//   p{
//     // text-indent: 12px;
//     padding: 0 24px;
//   }
//   p:nth-child(4){
//     margin-top: 48px;
//   }
// }
