%cvss-preset {
  display: flex;
  height: 26px;
  padding: 0;
  color: #fff;
  font-weight: 700;
  width: 32px;
  justify-content: center;
  align-items: center;
}
.accountActions{
  margin: 8px 0 16px 0;
}
.configBlock{
  margin-left: 34px;
}

.numpad{
  height: 34px;
  box-sizing: border-box;
  max-width: 588px;
  color: #333;
  border: 1px solid #ccc;
  line-height: 140%;
}
.settingsTable{
  margin-top: -16px;
  margin-left: -32px;
  border-spacing: 32px 16px;
  tr td:first-child{
    width: fit-content;
    min-width: 200px;
  }
  td{
    vertical-align: baseline;
    & > span{
      max-width: 240px;
      margin-left: 34px;
      display: block;
    }
  }
}
