// @import "../../../vars";

.repositories_table {
  > table > thead {
    display: none;
  }
  > table > tbody {
    > tr {
      &:hover {
        background-color: inherit !important;
      }
      > td {
        border: none !important;
        padding: 0;
      }
    }
  }
}

.imageRepList{
  :global(.k-grid-content) {
    overflow: auto !important;
  }
  :global(.k-grid-header){
    padding-right: 2px !important;
  }
  .totalItemsBlock{
    margin: 4px 0 12px 0;
  }
}

// .image-register-tabs .k-tabstrip {
//   margin-top: 24px;
// }

// // .image-register-tabs > *:not(.common-header-page) {
// //   margin-left: 40px;
// //   margin-right: 40px;
// // }

// .modal-add-repository-content{
//   padding: 24px;
//   .add-repository-input{
//     span{
//       display: inline-block;
//       margin-bottom: 8px;
//     }
//     .selected-repository-credential{
//       div {
//         span:nth-child(1){
//           display: block;
//           margin-bottom: 4px;
//           border-bottom: 1px solid black;
//         }
//       }
//     }
//     margin-top: 16px;
//   }
//   .add-repository-input-checkbox{
//     span{
//       margin-right: 12px;
//     }
//     margin-top: 16px;
//   }
// }
// .selected-repository-credential{
//   div {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     border-bottom: 1px solid rgb(224, 224, 224);
//     padding: 0 0 0 8px;
//     span{
//       display: inline-block;
//       margin: 0;
//     }

//   }
// }
// table.add-register-form-table{
//   margin-top: 12px;
//   width: 100%;
//   th{
//     text-align: left;
//   }
//   td{
//     border-bottom: 1px solid rgb(224, 224, 224);
//   }
//   td:last-child{
//     text-align: right;
//   }
// }
// .add-registry-info-title{
//   margin-bottom: 4px !important;
// }
// .add-registry-repository{
//   margin-top: 0px !important;
//   border: 1px solid rgb(224, 224, 224);
//   padding: 12px 12px 16px 12px;
//   .add-registry-info{
//     margin-bottom: 8px;
//     color: grey;
//   }
// }
// // .repository-list {
// //   .row{
// //     min-height: 36px;
// //     border-top: 1px solid #f2f2f2;
// //     display: flex;
// //     align-items: center;
// //   }
// // }
// .repository-list {
//   .k-i-plus::before{
//     height: 16px;
//     content: url("../../../../public/images/plus_button.svg");
//   }
//   .k-i-minus::before{
//     height: 16px;
//     content: url("../../../../public/images/minus_button.svg");
//   }
//   // .k-grid .k-master-row{
//   //   border-bottom: none;
//   // }
//   .row{
//     min-height: 36px;
//     // border-top: 1px solid #f2f2f2;
//     display: flex;
//     align-items: center;
//     font-weight: bold;
//   }
//   > .k-grid > table {
//     // margin-top: -40px;
//     // border-spacing: 0 40px;
//     // border-collapse: unset;
//     > tbody > tr
//     > .k-grid-header{
//       display: none;
//     }
//     > thead {
//       display: none;
//       // > tr:hover {
//       //   background-color: inherit;
//       // }
//     }
//     > tbody > tr{
//       &:hover{
//         background-color: inherit;
//       }
//       > td{
//         border-bottom: 40px solid white;
//       }
//       // > td {
//       //   border-bottom: 1px solid #f2f2f2;
//       // }
//     }
//   }
//   .image-repository-list{
//     .k-pager{
//       margin-top: 0 !important;
//       height: 36px;
//     }
//   }
//   .custom-search{
//     margin-left: 40px;
//   }
//   // .k-grid table tr{
//   //   border-bottom: none;
//   // }
//   .k-grid-header{
//     border-color: #f2f2f2;
//     background-color: inherit;
//   }
//   .rep-images{
//      // .k-grid-content{
//     //   max-height: 200px;
//     // }
//     // .k-cell-inner > .k-link{
//     //   padding-left: 4px;
//     // }
//     // border-bottom: 1px solid #f2f2f2;
//     // tr{
//     //   border-top: 1px solid #f2f2f2;
//     // }
//     // th:first-child, td:first-child{
//     //   padding-left: 40px;
//     // }
//     // th:nth-child(4), th:nth-child(5) {
//     //   .k-cell-inner > .k-link:hover {
//     //    cursor: default;
//     //   }
//     // }
//   }
//   .main-in-line{
//     display: flex;
//     align-items: center;
//     height: 36px;
//   }
//   td{
//     vertical-align: baseline;
//   }
//   td[colspan]{
//     vertical-align: middle;
//   }
//   .total-items-block{
//     margin-top: 8px;
//     color: #aaaaaa;
//     font-size: 11px;
//   }
// }
// .registry-task{
//   > .k-widget, > .k-input{
//     margin-left: 0;
//   }
// }
