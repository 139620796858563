.tdAssetMutableVals{
  display: flex;
  align-items: center;
  :global(.k-dropdownlist) {
    border: none !important;
    padding-left: 0;
    padding-bottom: 0;
    &:hover{
      background-color: white;
    }
  }
  :global(.k-list-item){
    border: none !important;
  }
  .questionWrapper{
    margin: 0 0 1px 8px;
  }
}


.assetMutableValsSaved{
  margin-left: 6px;
}

