.containerOfGroups{
  :global(.k-animation-container) {
    margin-top: -24px;
  }
  .dynamicGrList{
    td:nth-child(2){
      .latestAssetsCountWrap{
        display: flex;
        justify-content: space-between;
        .latestAssetsCountDate, .latestAssetsCountValue{
          color: #999494;
        }
      }
    }
  }
}
.dynamicGroupDescriptionFilters{
  position: relative;
  margin-bottom: 12px;
  padding: 16px 28px;
  .filterAssetsTitleWrap{
    margin: -14px 0 20px 0;
    .filterAssetsTitle{
      font-size: 16px;
    }    
  }
  :global(.filter-line){
    .saveFilters{
      margin-left: 32px;
      background-color: inherit;
    }
  }
  :global(.k-input){
    background-color: inherit;
  }
  .dynamicGroupFilterActinsWrap{
    display: flex;
    margin-top: 20px;
    .btnOrWrap{
      display: flex;
      .divLine{
        border-top: 1px dashed #ccc;
        width: 606px;
        margin-top: 18px;
        margin-left: 12px;
      }
    }
  }
  .btnsWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 48px;
    width: 374px;
  }
  .appliedOfDynamicFilters, .processing, .errorWrap{
    font-size: 14px;
    font-style: italic;
    position: absolute;
    bottom: 68px;
  }
  .errorWrap{
    color: #ef664f;
    font-style: normal;
    max-height: 20px;
    overflow: auto;
  }
  .appliedOfDynamicFilters{
    color: #00850b;
  }
  .processing{
    color: #ededed;
  }
}
.dynamicGroupFilterBlock{
  margin-top: 20px;
  padding-top: 16px;
}
.dynamicGroupMetaBlock{
  position: relative;;
}