.popup-menu{
    cursor: pointer;
    display: flex;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    &:hover .popup-content{
        display: flex;
    }
    .popup-content{
        display: none;
        width: 100%;
        // display: flex;
        flex-direction: column;
        position: absolute;
        top: 0;
        left: calc(100%);
        background: white;
        box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
    }
}

// .popup-menu{
//     cursor: pointer;
//     display: flex;
//     position: relative;
//     align-items: center;
//     justify-content: flex-end;
//     width: 10vw;
//     &:hover .menu{
//         display: flex;
//         // z-index: 333333;
//     }
//     .menu{
//         display: none;
//         // display: flex;
//         position: absolute;
//         margin-top: 60px;
//         flex-direction: column;
//         border: 1px solid black;
//         background: white;
//         color: black;
//     }
//     .btn{
//         position: relative;
//     }
//     .btn:hover{
//         .left-menu{
//             // top: 0;
//             // right: -16px;
//             display: flex;
//             flex-direction: column;
//         }
//     }
//     .left-menu{
//         display: none;
//         // display: flex;
//         position: absolute;
//         top: 0;
//         // right: 0;
//     }
//     // display: none;
//     // position: absolute;
//     // box-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
//     // background-color: $background-color;
// }