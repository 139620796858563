.host-list{
  th:nth-child(6){
    span.k-link{
      cursor: inherit;
    }
  }
}
// @import "./src/vars";
// %cvss-preset {
//     display: flex;
//     height: 26px;
//     padding: 0;
//     color: #fff;
//     font-weight: 700;
//     width: 32px;
//     justify-content: center;
//     align-items: center;
// }
// .none {
//     @extend %cvss-preset;
//     background-color: #cccbcb;
//     color: white;
//     font-weight: 500;
// }

// .low {
//     @extend %cvss-preset;
//     background-color: #00bb00;
// }

// .medium {
//     @extend %cvss-preset;
//     background-color: #ffb800;
// }

// .high {
//     @extend %cvss-preset;
//     background-color: #ff6200;
// }

// .critical,
// .ten-value {
//     @extend %cvss-preset;
//     background-color: #dc0000; // #780000;
// }

// /*
// .ten-value{
//   padding: 4px 6px;
// }
//  */

// .host-list, .image-list, .registry-list{
//     @extend %content-page;
//     .k-widget{
//         margin-left: 0;
//     }
//     overflow: auto;
// }

// .host, .image, .registry {
//     position: relative;
//     @extend %content-page;
//     &__system {
//         // position: relative;
//         display: flex;
//         align-items: center;
//         justify-content: left;
//         overflow: hidden;
//         img {
//             max-width: 20px;
//             max-height: 20px;
//             margin-right: 8px;
//             filter: opacity(0.4) drop-shadow(0 0 0 rgb(15, 15, 15));
//         }
//         .k-notification-group {
//             margin-top: 50px;
//             position: absolute;
//             overflow: hidden;
//             display: flex;
//             .k-animation-container {
//                 position: relative;
//                 .k-notification {
//                     position: relative;
//                     background-color: grey;
//                     display: block;
//                 }
//                 .k-notification-wrap {
//                     display: block;
//                 }
//             }
//         }
//     }
//     &__agent {
//         display: flex;
//         align-items: center;
//         // span:nth-child(2) {
//         //     margin-left: 8px;
//         // }
//         .scan-type{
//             display: flex;
//             align-items: center;
//             // justify-content: space-between;
//             span:nth-child(2){
//                 margin: 0 0 0 4px;
//                 font-size: 25px;
//             }
//             span:nth-child(3){
//                 margin: -2px 0 0 6px;
//                 font-size: 18px;
//             }
//             svg{
//                 margin-right: 12px;
//             }
//             img {
//                 width: 24px;
//                 height: 24px;
//                 // padding: 8px 0px 0px 0px;
//                 margin-left: 4px;
//             }
//         }
//     }
//     &__system{
//         span:nth-child(3){
//             margin-left: 4px;
//         }
//     }
//     &__menu {
//         @extend %dd-menu;
//     }
//     .k-filter-container {
//         margin-bottom: 0;
//     }
//     .k-grid .k-grid-container {
//         overflow: visible;
//         z-index: 9999;
//     }
//     .k-grid-content {
//         overflow: visible;
//     }
//     .k-grid {
//         th:last-child,
//         td:last-child {
//             // width: 4px;
//             position: relative;
//             overflow: visible;
//         }
//         .k-i-plus::before {
//             content: url("../../../../../public/images/plus_button.svg");
//           }
//         .k-i-minus::before {
//             content: url("../../../../../public/images/minus_button.svg");
//           }
//     }
//     .k-button-group {
//         display: none;
//     }
//     .k-widget .k-toolbar {
//         span:nth-child(3) button {
//             display: none;
//         }
//     }
//     .k-widget .k-filter {
//         margin-bottom: -32px;
//     }
//     .red {
//         background-color: #d9001b;
//         color: #fff;
//         font-weight: 700;
//     }
//     .yellow {
//         background-color: #dc851f;
//         color: #fff;
//         font-weight: 700;
//     }
//     .up {
//         color: green;
//         margin-left: 8px;
//     }
//     .down {
//         margin-left: 8px;
//         color: rgb(206, 53, 53);
//     }
//     .main-in-line{
//         display: flex;
//         align-items: center;
//         height: 36px;
//     }

//     // .custom-search{
//     //     width: 25%;
//     //     margin-bottom: 20px;
//     // }
// }
// .image-list {
//     td{
//         vertical-align: baseline;
//     }
//     td[colspan]{
//         vertical-align: middle;
//     }
// }

// // .host > *:not(.common-header-page) {
// //     overflow: auto;
// //   }
