@import '../../../vars';
$background-color: $dark-grey;

.header {
  box-sizing: border-box;
  width: 100%;
  background: $background-color;
  height: 48px;
  color: white;
  display: flex;
  justify-content: space-between;
  padding: 0 32px;
  fill: white;
  overflow: visible;
  button {
    padding: 0;
    border: none;
    color: inherit;
    transition: opacity 0.5s;
    &:hover {
      opacity: 0.7;
      transition: opacity 0.5s;
    }
  }

  .logo_button {
    display: flex;
    align-items: center;
    margin: 0 24px;
    span {
      display: flex;
      margin: 0 12px;
      padding: 4px 12px;
      border-radius: 8px;
    }
    img {
      height: 28px;
    }
    img + img {
      padding: 0 8px;
    }
  }

  // Все контейнеры видимых иконок и всплывающего меню
  .quicks {
    display: flex;
    align-items: center;
    img {
      max-height: 20px;
    }
  }

  // Контейнер видимых иконок и всплывающего меню
  .quick_menu {
    // Контейнер иконок всплывающего меню
    &.user_data {
      margin-left: 24px !important;
    }
    .dropdown_content {
      left: -24px;
      overflow: visible;
    }
    .dropdown_content_user {
      left: 44px;
      width: 120px;
    }
    .iconset {
      cursor: pointer;
      padding: 8px;
      display: flex;
      align-items: center;
      transition: opacity 0.5s;
      &:hover {
        opacity: 0.7;
        transition: opacity 0.5s;
      }
      .droptitleAccount{
        font-style: italic;
        color: #b1b0b0;
      }
    }
    img + img {
      margin-left: 8px;
    }
    span + img {
      margin-left: 8px;
    }
  }

  .quick_menu + .quick_menu {
    margin-left: 12px;
  }
}

.plus {
  border: 1px solid #f2f2f2;
  border-radius: 4px;
}

.content {
  display: flex;
  // width: 100vw;
}
