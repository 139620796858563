.resultKbInstall{
  padding: 16px 24px 32px 24px;
  // border: 1px solid #ccc;
  border-radius: 5px;
}
.resultEmpty{
  margin-top: 8px;
  text-align: center;
}
.filterWrap{
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  grid-gap: 0 60px;
  flex-wrap: wrap;
}
.exportToExcel{
  color: #00850b !important;
}
