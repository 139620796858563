.add_registry_info {
  color: #666;
  font-size: 12px;
}
.add_button {
  //   color: #00850b;
  color: #44c74f;
}

.form_table {
  td,
  th {
    height: 36px;
    box-sizing: border-box;
    padding: 0;
    button {
      padding: 0;
    }
  }
  tr:last-child td {
    border: none;
  }
  .delete_button_th {
    //   background-color: red;
    width: 40px;
  }
  .delete_button {
    color: #44c74f;
  }
}

.add_registry_repository,
.form_block {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
}

.credentialRepository{
  margin-top: 8px;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 8px;
}
