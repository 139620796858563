// @import "./src/vars";

.scope {
  //   a {
  //     display: flex;
  //     gap: 0 4px;
  //   }
  //   span:first-child {
  //     background-color: #00850b;
  //   }
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  .scope_type {
    // background-color: #00850b;
    background-color: #333;
    margin-right: 4px;
  }
}

.notification {
  position: absolute;
  margin: 12px 0 0 -40px;
}

.hostname {
  //   background: red;
  display: flex;
  &::before {
    content: url('../../../../public/images/left_menu_icons/hosts-menu-icon.svg');
    // background: url('../../../../public/images/left_menu_icons/hosts-menu-icon.svg');
    // width: 15px;
    // height: 15px;
    // position: absolute;
    margin-right: 6px;
    // mask-image: url('../../../../public/images/left_menu_icons/hosts-menu-icon.svg');
    // mask-position: center;
    // mask-size: 100%;
    // background-color: red;
    // color: red;
    // fill: red;
    // background: red;
  }
}
.groupname {
  background: blue;
}

.assetscope {
  display: flex;
  gap: 4px 12px;
  .assets {
    display: flex;
    overflow-x: hidden;
    gap: 0 12px;
  }
}

.action_header_cell {
  display: flex;
  align-items: center;
  gap: 0 8px;
  cursor: pointer;
  span.types_info {
    display: flex;
    z-index: 1;
    &:hover {
      .types_info_content {
        display: flex;
      }
    }
    .types_info_content {
      display: none;
      z-index: 1000;
      flex-direction: column;
      position: absolute;
      margin-top: 12px;
      margin-left: -28px;
      background-color: white;
      gap: 8px 0;
      text-transform: none;
      font-weight: normal;
      box-shadow: 0px 4px 6px 0px rgba(146, 146, 146, 0.25),
        0px 0px 4px 0px rgba(146, 146, 146, 0.25);
      padding: 12px 20px;
      .type_info {
        gap: 0 8px;
        display: flex;
      }
    }
  }
}
.cellOsType::first-letter {
  display: inline-block;
  text-transform: uppercase !important;
}