.login_page {
  display: flex;
  width: 100vw;
  height: 100vh;
  input {
    // max-width: ;
  }
}

.login_form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  flex-direction: column;
  .form_legend {
    color: #333;
    font-size: 28px;
    font-weight: 700;
    padding: 0;
  }
  .field_block {
    input[type='text'],
    input[type='password'] {
      width: 500px;
    }
  }
  .form_legend + .field_block {
    margin-top: 28px;
  }
  .field_block + .field_block {
    margin-top: 20px;
  }
}

.login_back {
  background: url('../../../../public/images/login-background.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
  width: 58%;
  position: relative;
  overflow: hidden;
  // flex-grow: 1;
  // img {
  //   width: 50%;
  // }
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  img {
    width: 50%;
  }
  // img {
  //   width: 100%;
  // }
}

.gradient {
  width: 300%;
  height: 150%;
  background: radial-gradient(#f6a82d, transparent 70%);
  position: absolute;
  top: 0;
  right: -200%;
  top: -80%;
}

// .login-page{
//   background: url(https://docs.vulns.io/images/background.svg);
//   background-size: cover;
//   height: 100vh;
//   // position: relative;
//   display: flex;
//   justify-content: center;
//   align-items: center;
// }

// .login-form{
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   height: 400px;
//   .company-info{
//     font-size: 16px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     height: 100%;
//     width: 380px;
//     color: #818181;
//     border-radius: 8px;
//     h1{
//       font-size: 48px;
//       margin: 0;
//     }
//     ul{
//       padding: 0 16px;
//       margin-top: 0;
//     }
//   }
//   form{
//     // height: 100%;
//     // height: 400px;
//     // max-width: 600px;
//     width: 360px;
//     border: none;
//     margin: 0 200px 0 0;
//     box-shadow: 0 0 2px rgba(0,0,0,.5);
//     background: white;
//     .k-form__field-block{
//       margin-bottom: 12px;
//     }
//     .k-form-legend{
//       font-size: 24px;
//       font-weight: 700;
//       border: none;
//       margin-bottom: 0;
//       color: #818181;
//     }
//     button{
//       font-weight: 700;
//       background: #818181;
//       color: white;
//       padding: 8px 32px;
//       border: 2px solid #818181;
//       &:hover{
//         background: inherit;
//         color: #818181;
//         transition: background 0.5s;
//       }
//     }
//     .k-checkbox{
//       border: 2px solid #818181;
//       &:checked{
//         background-color: #818181;
//       }
//     }
//   }
// }

// .login-info-block{
//   width: 360px;
// }

// .k-form{
//   max-width: 640px;
//   margin: 48px auto;
//   border: 4px solid rgb(216, 216, 216);
//   border-radius: 4px;
//   padding: 24px;
//   &__field-block{
//     height: 68px;
//   }
//   // &__field-stayon{
//   //   // margin-top: -16px;
//   //   .checkbox{
//   //     margin-top: '16px'
//   //   }
//   // }
// }
// .k-form-legend{
//   text-align: center;
// }
// // .k-d-flex{
// //   margin-bottom: 15px;
// // }
// .login-info-block{
//   text-align: center;
//   .error {
//     color: red;
//   }
// }
