.diffStatus{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    border: 1px solid #aaa;
    overflow: hidden;
    margin-right: 8px;
    span{
        flex-grow: 1;
        height: 100%;
        &.closed{
            background-color: rgba(255, 0, 0, 0.35);
        }
        &.new{
            background-color: rgba(0, 180, 0, 0.3);
        }
    }
}