.commonLogWrap{
  position: relative;
}
.commonLogBtnWrap{
  position: absolute;
  top: -4px;
  right: 12px;
  li:hover{
    color: #666666 !important;
  }  
}
.commonLogMenuWrap{
  position: absolute;
  top: 6px;
  right: 12px;
  // :global(.k-menu-link-text){
  //   font-size: 13px !important;
  // }
  li { 
    span{
    font-size: 13px !important;
  }
}    
  li:hover{
    color: #666666 !important;
  }  
}

