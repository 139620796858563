@import '../../../vars';

.btn {
  font-family: NotoSans, Arial, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  gap: 0 8px;
  vertical-align: middle;
  white-space: nowrap;
  border: 1px solid $border-color;
  border-radius: 8px;
  color: $button-text-color;
  font-size: 14px;
  padding: 8px 16px;
  background: white;
  transition: background 0.5s;
  &:hover:not(:disabled) {
    cursor: pointer;
    background-color: $button-hover-color;
    transition: background 0.5s;
  }
  &:focus {
    box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.08);
    outline: 0;
  }
  &:active {
    filter: brightness(90%);
  }
  &[type='submit'],
  &.action {
    background: $button-green-color;
    border-color: $button-green-color;
    color: white;
    &:hover:not(:disabled) {
      color: $button-green-color;
      background: white;
    }
  }
  &:disabled {
    box-shadow: none;
    cursor: default;
    filter: grayscale(0.1);
    opacity: 0.6;
  }
  &.menu {
    border-radius: 0;
    border: none;
    padding: 8px 16px;
    white-space: nowrap;
    background: transparent;
  }
  &.flat {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
  }
  &.link {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
    &:hover:not(:disabled){
      text-decoration: underline;
    }
  }
  &.dotted {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
    text-decoration: underline;
    text-decoration-style: dotted;
  }
  &.copy {
    padding: 4px;
    .copy_icon {
      width: 20px;
      height: 20px;
    }
  }
  &.text_green {
    color: $button-green-color;
  }
  &.task_asset {
    padding: 4px;
    gap: 4px 20px;
    color: $text-green-color;
    border: none;
    &:hover {
      text-decoration: line-through;
      background: inherit;
      // font-weight: bold;
      color: #666;
    }
    &:focus {
      box-shadow: none;
    }
  }
  &.modal {
    font-size: 13px;
    background-color: transparent;
    padding: 0;
    border-radius: 4px;
    display: inline-block;
    border: none;
    color: $main-text-color;
    text-decoration: underline;
    text-decoration-style: dotted;
    &:hover:not(:disabled) {
      // text-decoration: underline;
      background: transparent;
      color: black;
    }
  }
  &.excel {
    color: $text-green-color;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: 8px;
  }
  &.delete:hover:not(:disabled){
    background-color: #f20c0c;
    border-color: #f20c0c;
    color: white
  }
  .icon {
    background-color: $button-text-color;
  }
  // span:first-child {
  //   position: relative;
  //   left: -8px;
  // }
}
