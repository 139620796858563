.addUserForm{
  padding-top: 0;
  .addLocalUser, .addLdapUser{
    // max-width: 460px;
  }
  .typeOfAccountWrapper{
    margin: 32px 0 -12px 0;
    .labelSelectTypeAccount{
      display: block;
      margin-bottom: 6px;
    }
  }  
}
// .idInfoWrap{
//   display: flex;
//   flex-direction: column;
// }
.idinfoWrap{
  margin-top: -4px;
}
.idInfoContent{
  color: #666666;
}
.idInfoValue{
  color: #666666;
}
.credentialWrap {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
}
