// @import './src/vars';
// .devicedescription {
//   @extend %content-page;
//   .device-title{
//     display: flex;
//     align-items: center;
//     img{
//       width: 44px;
//       height: 44px;
//       margin: 0 16px  0 0;
//     }
//   }
//   &__os {
//     // position: relative;
//     display: flex;
//     align-items: center;
//     justify-content: left;
//     overflow: hidden;
//     margin-left: -8px;
//     img {
//         max-width: 48px;
//         max-height: 48px;
//         margin-right: 8px;
//         filter: opacity(0.45) drop-shadow(0 0 0 rgb(15, 15, 15));
//     }
//     span{
//       overflow: hidden;
//       text-overflow: ellipsis;
//     }
//   }
//   .device-os{
//     max-width: 320px;
//     overflow: hidden;
//     white-space: nowrap;
//   }

//   table.column-first td{
//     overflow: hidden;
//     text-overflow: ellipsis;
//   }
// }
.radioInputWrap{
  margin-bottom: 16px !important;
}
.radioInputBlock{
  display: flex;
  margin-bottom: 4px;
}
.resultCheckIp{
  margin-top: 8px;
  color: #f31700;
  font-style: italic;
}
.formCredentialTable {
  td,
  th {
    height: 36px;
    box-sizing: border-box;
    padding: 0;
    button {
      padding: 0;
    }
  }
  tr:last-child td {
    border: none;
  }
  .delete_button_th {
    //   background-color: red;
    width: 40px;
  }
  .delete_button {
    color: #44c74f;
  }
}
.credentialWrap {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
}