// // @import '../../../vars';

.number_of_assets {
  display: flex;
  justify-content: center;
  h2 {
    font-size: 48px;
  }
}

.total_assets {
  width: 84px;
}
.cvss_score {
  width: 56px;
}
.vulns_count {
  width: 128px;
}

.total_assets_info, .number_of_assets{
  table{
    margin-top: 16px;
    font-size: 20px;
  }
  .asset_type{
    font-weight: 600;
    font-size: 18px;
  }
  .asset_count{
    width: 56px;
  }
}

// .dashboard {
//   .task__topactions {
//     button:last-child {
//       margin-left: auto;
//     }
//   }
//   position: relative;
//   &__header {
//     display: flex;
//     width: 100%;
//     justify-content: space-around;
//     align-items: center;
//   }
//   .k-button-group {
//     display: none;
//   }
//   .k-widget .k-toolbar {
//     span:nth-child(3) button {
//       display: none;
//     }
//   }
//   .k-widget .k-filter {
//     margin-bottom: -32px;
//   }
//   &__link {
//     color: black;
//   }
//   .horizontal {
//     // @extend %table;
//     // @extend %table-horizontal;
//     width: 100%;
//     height: 100%;
//     overflow: scroll;
//   }
//   .horizontal td:last-child,
//   .horizontal th:last-child {
//     text-align: right;
//   }
//   .horizontal td:first-child {
//     padding: 0 16px;
//   }
//   // .horizontal th:last-child {
//   //   text-align: center;
//   // }
//   img {
//     max-width: 20px;
//     max-height: 20px;
//     margin-right: 8px;
//     filter: opacity(0.4) drop-shadow(0 0 0 rgb(15, 15, 15));
//   }
//   .os {
//     display: flex;
//     align-items: center;
//     justify-content: left;
//   }
//   .number-of-assets {
//     text-align: center;
//     h2 {
//       font-size: 48px;
//     }
//   }
//   .k-picker {
//     width: 200px;
//   }
//   .horizontal .top-vulns-asset td:first-child,
//   .horizontal .top-vulns-asset th:first-child {
//     max-width: 10px;
//   }
//   th.cvss-score {
//     width: 88px;
//   }
//   th.vulnerabilities-number {
//     width: 80px;
//   }
//   th.assets-number {
//     width: 60px;
//   }
//   th.ip-address {
//     width: 120px;
//   }
//   .auto-update-dashboard {
//     color: rgb(59, 59, 59);
//     font-weight: 500;
//     font-size: 14px;
//     margin: 0 8px 0 4px;
//   }

//   .dashboard-link-vulns {
//     .k-button-text {
//       border-bottom: 1px dashed black !important;
//       line-height: 0.8rem;
//     }
//   }
//   .k-button-link:hover {
//     text-decoration: none;
//   }
// }

// // .dashboard-table {
// //   .k-master-row td{
// //     text-align: center;
// //   }
// //   &__filtering-status{
// //     margin-left: -160px;
// //   }
// //   &__hostname{
// //     display: flex;
// //     justify-content: center;
// //     div{
// //       display: flex;
// //       flex-direction: column;
// //       align-items: start;
// //       span:nth-child(2){
// //         font-size: 12px;
// //         color: grey;
// //       }
// //     }
// //   }
// //   &__system{
// //     position: relative;
// //     div{
// //       display: flex;
// //       align-items: center;
// //       justify-content: center;
// //       img{
// //         max-width: 20px;
// //         max-height: 20px;
// //         margin-right: 8px;
// //         filter: opacity(0.4) drop-shadow(0 0 0 rgb(15, 15, 15));
// //       }
// //       span:nth-child(3){
// //         margin-left: 4px;
// //       }
// //     }
// //   }
// //   &__cvss{
// //     margin: 0 auto;
// //     width: 44px;
// //     height: 44px;
// //     font-size: 20px;
// //     border-radius: 50%;
// //     display: flex;
// //     align-items: center;
// //     justify-content: center;
// //   }
// //   &__vulnerabilities{
// //     div{
// //       display: flex;
// //       justify-content: left;
// //       align-items: center;
// //       span:nth-child(2){
// //         font-size: 12px;
// //       }
// //     }
// //   }
// // }
