.summary-info{
  margin: 16px auto;
  padding: 20px 20px;
  border: 4px solid rgb(216, 216, 216);
  border-radius: 4px;
  max-width: 650px;
}
.not-found{
  border-top: 4px solid rgb(216, 216, 216);
}
.error{
  color: rgb(238, 75, 75);
}
