.sensorButtonBlock{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.viewSwitch{
    display: flex;
    gap: 0 16px;
    align-items: center;
    font-size: 14px;
    color: #424242;
}