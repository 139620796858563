.vendor_metric_info {
  margin-top: 20px;
}

.related {
  display: flex;
  align-items: baseline;
  gap: 0 4px;
}

.kb_description_td {
  white-space: normal !important;
}

.vuln_soft_cell {
  display: flex;
  gap: 0 12px;
  .vuln_soft {
    overflow-x: hidden;
  }
}

.actionsWLWrap{
  margin-top: 16px;
  display: flex;
  justify-content: center;  
}
.whiteListGridTitle{
  margin-bottom: 16px;
  // font-style: italic;
  color: #666666;
  display: flex;
  align-items: center;
  img{
    height: 16px;
    width: 16px;
  }
  button{
    padding-left: 4px;
    font-style: inherit;
    color: #333 !important;
  }
}
.vulnsSoftWhiteList{
  // background: rgb(201, 199, 199);
  margin: 0 0 16px 0;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  :global(.k-grid), :global(.filter-line), :global(.k-widget), :global(.k-button)  {
    background-color: inherit;
  }
}
.vulnsKb, .vulnsSoftWhiteList{
  .kbUpdate{
    display: flex;
    margin-top: 16px;
    button:nth-child(1){
      margin-right: 20px;
    }
  }
}
.vulnsSoft, .vulnsSoftWhiteList, .securityVulns, .packageModalInfo{
  .pkgUpdate{
    display: flex;
    margin-top: 16px;
    button:nth-child(1){
      margin-right: 20px;
    }
  }
}
.vulnModal, .kbModalWrap{
  .addVuln{
    margin: 0px 0px 20px 0px;    
  }
}
.fstec_warning{
  width: 16px;
  height: 16px;
  background-image: url('../../../../../../public/images/attention_yellow.svg');
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
.fstec_no_info{
  color: #666;
}

:global(.dashboard__link){
  margin: 0 4px 0 4px;
}

.action_header_cell {
  display: flex;
  align-items: normal;
  gap: 0 8px;
  cursor: pointer;
  span.types_info {
    display: flex;
    z-index: 1;
    margin-top: -1px;
    &:hover {
      .types_info_content {
        display: flex;
      }
    }
    .types_info_content {
      display: none;
      z-index: 1000;
      flex-direction: column;
      position: absolute;
      margin-top: 28px;
      margin-left: -28px;
      background-color: white;
      gap: 8px 0;
      text-transform: none;
      font-weight: normal;
      box-shadow: 0px 4px 6px 0px rgba(146, 146, 146, 0.25),
        0px 0px 4px 0px rgba(146, 146, 146, 0.25);
      padding: 8px 12px;
      .type_info {
        gap: 0 8px;
        display: flex;
      }
    }
  }
}
// .vhead-cell{
//   :global(.k-icon){
//     margin-left: -4px;
//   }
// }


// .k-dialog {
//   .kbmodal__content {
//     padding: 16px !important;
//     .description-block {
//       margin-top: 8px;
//     }
//   }
// }
// .package-name-modal {
//   .k-window {
//     width: 100%;
//     max-width: 1400px;
//     max-height: 80%;
//     height: auto;
//   }
//   .taskaudit__content,
//   .kbmodal__content {
//     padding-left: 44px;
//     padding-right: 44px;
//     // height: 200px;
//     overflow: visible;
//     margin-bottom: 40px;

//     .description,
//     .title {
//       padding-bottom: 20px;
//       display: block;
//       font-weight: normal;
//     }
//     p:first-child {
//       font-weight: bold;
//     }
//     .related {
//       margin: 8px 0;
//     }
//   }
//   .auditvm__addition {
//     color: #7f7f7f;
//   }
//   .taskaudit__addition {
//     color: grey;
//   }
//   .taskaudit__reference-container {
//     margin: 8px 0 0 -5px;
//     div {
//       margin-bottom: 8px;
//     }
//     .taskaudit__reference a {
//       color: black;
//     }
//   }
//   .taskaudit__reference {
//     margin-left: 20px;
//   }
//   .k-button-link {
//     .k-button-text {
//       border-bottom: 1px dashed black !important;
//       line-height: 0.8rem;
//     }useAssetCreatePropContext
//   .k-button-link:hover {
//     text-decoration: none;
//   }
// }
.bulletinMutableBlockWrap{
    margin-top: 24px;
    // padding: 12px;
    overflow: auto;
    max-height: 140px !important;
    min-height: 60px !important;
    display: flex;
    flex-direction: column;
    .bulletinMutableBlockList{
      margin: 12px 0 16px 0;
      padding: 4px 12px;
      border: 1px solid #ccc;
      overflow: auto;
      max-height: 140px !important;
      min-height: 60px !important;
      border-radius: 5px;
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 4px 12px;      
    }
  }
  .kbToInclude{
    margin-right: 20px;
  }

  .vulnsSoft{
  .action_header_cell {
    display: flex;
    align-items: center;
    gap: 0 8px;
    cursor: pointer;
    span.types_info {
      display: flex;
      z-index: 1;
      &:hover {
        .types_info_content {
          display: flex;
        }
      }
      .types_info_content {
        display: none;
        z-index: 1000;
        flex-direction: column;
        position: absolute;
        margin-top: 12px;
        margin-left: -28px;
        background-color: white;
        gap: 8px 0;
        text-transform: none;
        font-weight: normal;
        box-shadow: 0px 4px 6px 0px rgba(146, 146, 146, 0.25),
          0px 0px 4px 0px rgba(146, 146, 146, 0.25);
        padding: 12px 20px;
        .type_info {
          gap: 0 8px;
          display: flex;
        }
      }
    }
  }
}