.helperWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  // .buttonHelper{
  //   margin-left: 8px;
  //   border: none;
  //   background: none;
  //   padding: 0;
  //   &:hover{
  //     cursor: pointer;
  //   }
  // }
  .titleHelper {
    font-size: 18px;
    color: #424242;
    vertical-align: baseline;
  }
}

.helper {
  display: flex;
  .button_helper {
    padding: 0;
    margin-left: 8px;
  }
}

