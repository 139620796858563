.check_license {
  color: #fff;
  background: #f20c0c;
  padding: 16px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  // button{
  //   margin-top: 4px;
  // }
  span{
    margin-left: 4px;
  }
  a {
    color: #fff;
    text-decoration: underline;
  }
  margin: 16px 0;
}
.dbFirstUpdatedInfoWrap {
  color: #000;
  background: #fecc09;
  // background: #f49c0f;
  padding: 16px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // max-width: 1130px;
  margin: 16px 0 -8px 0;
  .dbFirstUpdatedInfoContent{
    display: flex;
    align-items: center;
    span:first-child{
      display: block;
      margin-right: 16px;
    }
  }
  button{
    color: #000;
  } 
}
.agent_version {
  td:first-child {
    vertical-align: bottom;
  }
  td:last-child {
    display: flex;
    // align-items: center;
    img {
      opacity: 0.7;
      margin-right: 4px;
    }
    img + img {
      margin-left: 16px;
    }
  }
}

.setting {
  display: flex;
  flex-direction: column;
  color: #666;
  margin-bottom: 20px;
  .head {
    margin-bottom: 8px;
  }
}

:global(.system) {
  input{
    color: #666666;
  }
}

.settingInfo{
  color: #666666;
  margin-bottom: 12px;
  font-style: italic;
}

.update {
  h4 {
    color: var(#333);
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%; /* 25px */
  }
  .update_fields {
    display: flex;
    flex-wrap: wrap;
    gap: 12px 48px;
  }
  .update_field {
    display: flex;
    flex-direction: column;
    input:not([type='radio']) {
      width: 588px;
      margin: 8px 0;
    }
  }
  .buttons_group {
    padding-top: 20px;
    span {
      color: #00850b;
    }
  }
  .aftertable {
    margin-top: 48px;
  }
}

.update_channels {
  td,
  th {
    box-sizing: border-box;
  }
  .json-export *,
  .csv-export * {
    color: black;
    text-decoration: underline;
  }
  .channel_name_row {
    width: 260px;
  }
  .channel_type_row {
    width: 100px;
  }
  .channel_release_row {
    width: 228px;
  }
  .channel_update_row {
    width: 248px;
  }

  // .channels-header table {
  //   margin-bottom: 0;
  // }
  .channel_group {
    margin: 0;
    td {
      // padding: 16px 24px;
      padding: 0 12px;
      box-sizing: border-box;
      border: none !important;
    }
  }
  .channels_header tbody,
  .channel-table .k-grid-header {
    display: none;
  }
  .channel_table {
    border-left: 1px solid #ccc;
    margin-left: 21px;
    thead {
      display: none;
    }
    tr:hover {
      background: inherit !important;
    }
    tr td:first-child {
      padding-left: 46px;
    }
    td {
      border: none !important;
    }
  }
}
// .channel-table table {
//   margin: 0;
// }

.wlNotificationWrap{
  display: flex;
  // margin-top: 8px;
  bottom: 52px;
  margin-right: -28px;
  padding: 16px 12px;
  max-width: 500px;
  background-color: #44c74f;
  z-index: 999;
  color: white;
  position: fixed;
  // top: 140px;
  right: 36px;
  border-radius: 5px;
  font-size: 14px;
  .wlNotificationLinkWrap{
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin: 0 4px;
    a{
      color: white;
    } 
  }  
}

.save_button_block{
  display: flex;
  gap: 8px;
  align-items: center;
  .saved_message{
    color: rgb(153, 153, 153);
  }
}
