.asset_scope {
  margin-top: 8px;
}
.package_fast_filter {
  margin-top: 16px;
  display: flex;
  flex-wrap: wrap;
  gap: 0 12px;
}
.task_pkg_console {
  .title {
    margin-bottom: 16px;
  }
  .wrapper {
    background-color: #363f44;
    color: #fff;
    height: 400px;
    overflow: auto;
    padding: 16px 0 0 16px;
    border-radius: 8px;
  }
  .content {
    white-space: pre-line;
  }
}
.taskKbLog {
  // .title {
  //   margin-bottom: 16px;
  // }
  .wrapper {
    height: 400px;
    overflow: auto;
    // padding: 16px 0 0 16px;
    // border-radius: 8px;
  }
  .content {
    white-space: pre-line;
  }
}
.logProcessingWrap{
  display: flex;
  align-items: center;
}
.loaderWrap{
  margin: 8px 0 0 0;
}
.layerContent{
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100px;
}
.auditInfoMetaWrap{
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 8px;
  color: #333333;
  a{
    margin-left: 4px;
    display: inline-block;
  }
  img{
    width: 14px;
    height: 14px;
    margin-right: 4px;
  }
}
.logMessageColumn{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
  button{
    font-size: 13px !important;
  }
}
.logMenuColumn{
  position: relative;
  button{
    font-size: 13px;
  }  
}
.logCopiedInfoWrap{
  position: absolute;
  bottom: 12px;
  right: 32px;
  background: #ededed;
  color: #665;
  padding: 8px;
  border-radius: 4px;
}
.logMessageTa{
  background: none;
  border: 0px;
  display: block;
  font-size: 13px;
  resize: none;
  font-family: inherit;
  color: inherit;
  width: 100%;
}

