@import '../../../vars';
.dashboard_content {
  display: flex;
  gap: 12px;
}
.dashboard {
  padding: 12px 20px;
  border: 1px solid $border-color;
  border-radius: 8px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  h2 {
    margin: 0;
    // text-align: center;
  }
}
.vdbList{
  margin-top: 40px;
  position: relative;
}
