.score_block {
  display: flex;
  gap: 8px;
}
.reference_list {
  display: flex;
  flex-direction: column;
  gap: 12px 0;
  padding: 12px 0;
}

.aliases_info {
  display: flex;
  gap: 0 8px;
  margin-bottom: 12px;
}
.wlVulnModalAsset, .wlVulnModalDevice{
  margin: 0 0 16px 0;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
  .k-grid, .filter-line, .k-widget, .k-button  {
    background-color: inherit;
  }
}
.whitelistGridTitleTop{
  img{
    height: 16px;
    width: 16px;
  }
  // margin-bottom: 4px;
  // font-style: italic;
  color: #666666;
  display: flex;
  align-items: center;
  // justify-content: center;
  font-size: 14px;
  button{
    padding-left: 4px;
    font-style: inherit;
    color: #333 !important;
  }
  .whitelistGridText{
    display: inline-block;
    margin-right: 4px;
  }
}
.whitelistGridTitle{
  margin-bottom: 8px;
  // font-style: italic;
  color: #666666;
  display: flex;
  align-items: baseline;
  justify-content: center;
  font-size: 14px;
  button{
    padding-left: 4px;
    font-style: inherit;
    color: #333 !important;
  }
  .whitelistGridText{
    display: inline-block;
    margin-right: 2px;
  }
}
.titleBottom, .wlGridModalAsset{
  display: flex;
  justify-content: center;
}
.wlGridModalAsset{
  font-size: 14px;
  margin-bottom: 12px;
}
.tooltipWrap{
  display: flex;
  align-items: center;
  span:first-child{
    margin-right: 8px;
  }
}
.cweName{
  max-width: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.vulnsMetricItemFBlocl{
  margin-bottom: 4px;
}
.cisaKev{
  display: flex;
  a{
    margin-right: 32px;
  }
  a[target="_blank"]::after{
    background: url("../../../../../public/images/new_tab.svg");
    content: "";
    position: absolute;
    width: 16px;
    height: 16px;
    margin-left: 4px;
    margin-top: 1px;
  }
}
.exploitsWrap{
  margin-bottom: 24px;
}
.exploitsContent{
  margin-bottom: 4px;
}
.exploitData{
  font-weight: bold;
}

:global(.common-summary-page){
  .btnDotted {
    border-color: transparent !important;
    color: inherit;
    background: none !important;
    box-shadow: none !important;
    text-decoration: underline;
    text-decoration-style: dotted;
    &:hover{
      text-decoration: underline !important;
      text-decoration-style: dotted !important;
    }
  }
}

// .vuln-modal{
//   padding: 0 4px;
//   // width: 100%;
//   // max-width: 100%;

//   // .k-icon-button.k-button-md{
//   //   padding: 0;
//   // }

//   .vulns-description{
//     padding: 4px 80px 0 8px;
//     .description{
//       margin-bottom: 12px;
//     }
//     .aliases-info{
//       margin: 8px 0 8px 0;
//       &__prefix{
//         font-weight: bolder;
//       }
//       &__link{
//         border-bottom: 1px dashed black !important;
//       line-height: 0.8rem;
//       cursor: pointer;
//       }
//     }
//     .metrics-block{
//       ul{
//         margin-top: 4px;
//       }
//     }
//   }
//   .vulns-related{
//     .k-button-link{
//       .k-button-text{
//         border-bottom: 1px dashed black !important;
//         line-height: 0.8rem;
//       }
//     }
//     .k-button-link:hover{
//       text-decoration: none;
//   }
//     table{
//       width: 99% !important;
//     }
//     th:nth-child(1), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6){
//       .k-link{
//       cursor: default;
//       }
//     }
//     .k-filter-container{
//       margin-bottom: 0;
//     }
//     .k-grid .k-grid-container {
//       overflow: visible;
//       z-index: 9999;
//     }
//     .k-grid-content{
//       overflow: visible;
//     }
//     .k-grid{
//       th:last-child{
//         width: 40px;
//         position: relative;
//         overflow: visible;
//       }
//     }
//     .k-button-group{
//       display: none;
//     }
//     .k-widget .k-toolbar{
//       span:nth-child(3) button{
//         display: none;
//       }
//     }
//   }
//   .custom-search{
//     margin: 0 0 24px 100px;
//   }
//   .common-header-page{
//     padding-left: 40px;
//     display: flex;
//     align-items: center;
//     .k-i-warning{
//       // color: red;
//       font-size: 32px;
//       margin-top: 12px;
//     }
//     .title{
//       margin-left: 8px;
//     }
//   }
//   .common-summary-page{
//     margin-left: 40px;
//   }
//   .k-tabstrip {
//     margin-left: 40px;
//   }
//   .k-tabstrip {
//     margin-top: 12px !important;
//   }

//   .score-block{
//     display: flex;
//     // align-items: flex-end;
//     span:nth-child(2){
//       margin: 5px 0 0 4px;
//       color: #7f7f7f;
//     }
//   }

//   .vuln-modal-asset{
//     table{
//       width: 99% !important;
//     }
//     .k-filter-container{
//       margin-bottom: 0;
//     }
//     .k-grid .k-grid-container {
//       overflow: visible;
//       z-index: 9999;
//     }
//     .k-grid-content{
//       overflow: visible;
//     }
//     .k-grid{
//       th:last-child, td:last-child{
//         // width: 40px;
//         position: relative;
//         overflow: visible;
//       }
//     }
//     .k-button-group{
//       display: none;
//     }
//     .k-widget .k-toolbar{
//       span:nth-child(3) button{
//         display: none;
//       }
//     }
//     .k-widget .k-filter{
//       margin-bottom: -32px;
//     }

//   }
//   .nodata-content{
//     margin: 12px 0 0 40px;
//   }
//   .k-panelbar > .k-item > .k-link, .k-panelbar > .k-panelbar-header > .k-link {
//     color: black;
//     font-weight: 600;
//     // background-color: #d5d5d78c;
//   }
//   .k-panelbar > .k-item > .k-link.k-state-selected, .k-panelbar > .k-panelbar-header > .k-link.k-state-selected {
//     background-color: #efefef;
//   }
//   // .k-tabstrip-top .k-state-active .k-animation-container-relative{
//   //   margin: 0 0 0 16px;
//   // }
//   .k-child-animation-container{
//     margin-left: 16px;
//   }
//   .k-tabstrip .k-content{
//     margin: 8px 0;
//   }
//   .k-i-arrow-chevron-up::before {
//     content: "\e121";
//   }
//   .k-i-arrow-chevron-down::before {
//     content: "\e11e";
//   }
// }

// // .vuln-modal-nodata{
// //   .common-header-page{
// //     padding-left: 40px;
// //     display: flex;
// //     align-items: center;
// //     .k-i-warning{
// //       // color: red;
// //       font-size: 32px;
// //       margin-top: 12px;
// //     }
// //     .title{
// //       margin-left: 8px;
// //     }
// //   }
// //   height: 300px;
// //   width: 600px;
// //   margin-left: 30%;
// //   margin-top: 180px;
// //   .nodata-content{
// //     margin: 12px 0 0 40px;
// //   }
// // }
