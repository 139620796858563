.add_windows{
    padding: 0;
}
.isDisabled{
    color: #b3b4b9
}
.isDisabledActions{
    filter: grayscale(0.8);
    opacity: 0.5;
}
.maintenanceWindowBlock{
    border: 1px solid #ccc;
    padding: 0 24px;
    border-radius: 8px;
    h4{
        font-weight: 500;
        font-size: 20px;
    }
}
.windowModal{
    .timeNameTd{
        width: 100px;
    }
}
.windowEditModal{
    h4{
        font-weight: 600;
        margin: 12px 0;
    }
}
.scheduleTable{
    td{
        padding: 8px 0;
        &:nth-child(2){
            width: fit-content;
            padding-right: 8px;
        }
        &:last-child{
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }
}