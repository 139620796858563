.info_block {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px 24px;
}

.scores_info {
  .score_name {
    font-weight: 700;
  }
}

.vector_info {
  display: flex;
  gap: 0 24px;
  align-items: stretch;
}

.metric_title {
  font-size: 24px;
  font-weight: 700;
}

.metric_info_table {
  padding-left: 16px;
  border-spacing: 0 16px;
  td {
    vertical-align: baseline;
  }
  .metric_name {
    // white-space: nowrap;
    padding-right: 8px;
    width: 240px;
    box-sizing: border-box;
  }
  .metric_score_value {
    font-weight: 700;
    color: #333;
  }
  .score_values {
    display: flex;
    gap: 16px;
    color: #999;
    font-weight: 400;
    padding-bottom: 8px;
  }
  .metric_description {
    color: #666;
  }
}

// .vector-modal {
//   padding: 0 4px;
//   .common-header-page {
//     padding-left: 40px;
//     display: flex;
//     align-items: center;
//     .k-i-info,
//     .k-i-warning {
//       // color: red;
//       font-size: 32px;
//       margin-top: 12px;
//     }
//     .title {
//       margin-left: 8px;
//     }
//   }
//   .common-summary-page {
//     margin-left: 40px;
//   }
//   .vector-content {
//     margin-left: 40px;
//     .base,
//     .temporal,
//     .environmetal {
//       table {
//         td {
//           vertical-align: top;
//         }
//         td:first-child {
//           white-space: nowrap;
//           padding-right: 20px;
//         }
//         .score-values {
//           > :not(:first-child)::before {
//             content: '|';
//             padding: 0 12px;
//             // background: red;
//           }
//           // color: rgb(127, 127, 127);
//           .metric-score-value {
//             text-decoration: underline;
//             font-weight: bold;
//             // padding-right: 12px;
//           }
//           .alternative-score-name-info {
//             // padding: 0 12px;
//             color: #bfbfbf;
//           }
//         }
//         // td:last-child{
//         //   padding-left: 12px;
//         // }
//       }
//       .metric-description {
//         display: block;
//         padding: 8px 0 12px;
//       }
//       .metric-title {
//         font-weight: 700;
//       }
//       .submetric-title {
//         margin-left: 20px;
//       }
//       .metric-info-table {
//         margin-left: 40px;
//         margin-right: 20px;
//       }
//     }
//     .vector-info {
//       margin: 20px 0 40px;
//       display: flex;
//       .k-chart {
//         margin: 0 20px;
//       }
//       .score-info {
//         margin-right: 40px;
//         tr td:first-child {
//           text-align: right;
//         }
//         .score-name {
//           font-weight: 700;
//         }
//       }
//     }
//   }
// }
