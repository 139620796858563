.webhookWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100%;
  .webhookContent {
    margin-top: 44px;
    border: 1px solid rgb(192, 192, 192);
    padding: 16px 16px;
    .webhookUrl {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
    }
    .webhookDescriptionTitle {
      border-top: 1px solid rgb(192, 192, 192);
      padding: 10px 0px;
    }
    .webhookDescription {
      margin-bottom: 20px;
    }
    .webhookDescriptionInfo {
      display: block;
      margin-bottom: 4px;
    }
    .webhookDescriptionInfo2 {
      display: block;
      margin: 12px 0px 4px 0px;
    }
    .webhookCurlExample {
      margin-bottom: 8px;
      .webhookCurlTitle {
        font-style: italic;
        margin-bottom: 4px;
      }
    }
  }
}
// .formContent{
//   width: 880px;
// }
.webhookBtn {
  padding: 8px 12px;
  margin: 8px 0 8px 16px;
}
