.field_wrapper {
  label {
    margin: 0;
    margin-bottom: 8px;
  }
  .dropdown {
    margin: 8px 0 0 0;
  }
}
.taskScopeWrap{
  :global(.k-state-active){
    top: 1px !important;
  }
}
.applyAssets{
  min-height: 40px;
  max-height: 292px;
  overflow: auto;
  display: flex;
  flex-direction: column;
  // align-items: center;
  // flex-wrap: wrap;
  button{
    font-size: 12px;
  }
  .titleScopeAsset{
    margin-bottom: 4px;
    color: #999
  }
  .assetWrap{
    display: flex;
    flex-wrap: wrap;
  }
  .assetContentWrap{
    border: 1px solid #b8c4b9;
    border-radius: 5px;
    margin: 0 8px 8px 0;
    padding: 0 4px;
  }
  .itemWithIcon{
    display: flex;
    align-items: center;
    .scope_type {
      background-color: #00850b;
      margin: 0 2px 0 4px;
    }
    border: 1px solid #b8c4b9;
    border-radius: 5px;
    margin: 0 8px 8px 0;
    padding: 0 4px;
  }
}
.formCredentialTable {
  td,
  th {
    height: 36px;
    box-sizing: border-box;
    padding: 0;
    button {
      padding: 0;
    }
  }
  tr:last-child td {
    border: none;
  }
  .delete_button_th {
    //   background-color: red;
    width: 40px;
  }
  .delete_button {
    color: #44c74f;
  }
}


