%cvss-preset {
  display: flex;
  height: 26px;
  padding: 0;
  color: #fff;
  font-weight: 700;
  width: 32px;
  justify-content: center;
  align-items: center;
}
.credentialActions{
  margin: 8px 0 16px 0;
}
