@import '../../../vars';

.cred_menu {
  top: 0;
  left: 16px;
  //   padding: 16px;
  > button {
    padding: 8px 16px !important;
  }
  .break {
    height: 1px;
    width: calc(100% - 32px);
    background-color: $lightest-grey;
    display: block;
    box-sizing: border-box;
    margin: 8px 16px;
  }
}
.credentials_button {
  justify-content: left;
}
