.cvss_score, .v_score{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px 2px; // like new
  width: 30px;
  border-radius: 4px; // like new
}

.cvss_score {
  color: white;
  background-color: #ededed;
  // border-width: 1px 3px;
  border-width: 1px;
  border-style: solid;
  border-color: #ededed;
  &.critical {
    background-color: #f20c0c;
    border-color: #f20c0c;
  }
  &.high {
    background-color: #ff6200;
    border-color: #ff6200;
  }
  &.medium {
    background-color: #f79009;
    border-color: #f79009;
  }
  &.low {
    background-color: #12b76a;
    border-color: #12b76a;
  }
  &.none {
    color: #999;
  }
}
.v_score{
  color: white;
  background-color: transparent;
  // border-width: 1px 3px;
  border-style: solid;
  border-width: 1px;
  // width: 26px;
  &.critical {
    color: #f20c0c;
  }
  &.high {
    color: #ff6200;
  }
  &.medium {
    color: #f79009;
  }
  &.low {
    color: #12b76a;
  }
  &.none {
    color: #999;
  }
}
