@import '../../../vars';

.inputBlock{
  display: flex;
  flex-direction: column;
  max-width: 588px;
  width: 100%;
  .errorMsg{
    // color: #D51923;
    color: #c45050;
    font-size: 13px;
  }
}
.input {
  color: $main-text-color;
  // max-width: 588px;
  border: 1px solid $border-color;
  border-radius: 8px;
  line-height: 140%;
  background-color: inherit;
  box-sizing: border-box;
  height: 34px;
  padding: 0 16px;
  font-size: 13px;
  // width: 100%;
  display: inline-flex;
  flex-flow: row nowrap;
  text-align: start;
  align-items: stretch;
  vertical-align: middle;
  font-weight: normal;
  outline: 0;
  box-shadow: none;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  &:is([type="time"]){
    font-family: unset;
  }
  &:disabled{
    color: #999;
  }
  &.error{
    // border-color: #D51923;
    border-color: #c45050;
  }
}
.label{
  margin-bottom: 8px;
}
.secure {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  input {
    padding-right: 40px;
  }
  .secure_button {
    position: absolute;
    right: 0;
    padding: 4px;
    margin-right: 12px;
  }
}
.icon_button{
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  position: absolute;
  padding: 4px;
  border: none;
}
.icon{
  width: 20px;
  height: 20px;
  
}
.icon_calculator{
  background: url('../../../../public/images/calculator-icon.svg');
}
