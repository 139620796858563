.action_header_cell {
  display: flex;
  align-items: normal;
  gap: 0 8px;
  cursor: pointer;
  span.types_info {
    display: flex;
    z-index: 1;
    margin-top: -1px;
    &:hover {
      .types_info_content {
        display: flex;
      }
    }
    .types_info_content {
      display: none;
      z-index: 1000;
      width: 300px;
      flex-direction: column;
      position: absolute;
      margin-top: 28px;
      margin-left: -28px;
      background-color: white;
      gap: 8px 0;
      text-transform: none;
      font-weight: normal;
      box-shadow: 0px 4px 6px 0px rgba(146, 146, 146, 0.25),
        0px 0px 4px 0px rgba(146, 146, 146, 0.25);
      padding: 12px 20px;
      .type_info {
        gap: 0 8px;
        display: flex;
        margin-bottom: 4px;
        font-size: 13px;
      }
    }
  }
  .info_table{
    width: auto;
    margin-top: 0 !important;
    tr {
      height: 30px !important;
      td{
        border-bottom: none !important;
        padding: 0;
        height: 32px;
      }
    }
    .score_cell{
      width: 72px;
    }
    // .score_cell_agent {
    //   width: 94px;
    //   text-align: center;
    //   > span {
    //     mask-image: url('../../../public/images/agent_status_icon.svg');
    //     background-color: #666;
    //     display: inline-block;        
    //     width: 14px;
    //     height: 14px;
    //     margin-right: 40px;
    //   }
    // }
    // .score_cell_cred {
    //   width: 94px;
    //   > span {        
    //     text-transform: uppercase;
    //     font-size: 9px;
    //     font-weight: bold;
    //     border: 0.8px solid #ededed;
    //     padding: 3.2px 4.8px;
    //     margin-right: 10px;
    //   }
    // }
  }
  .info_table_agent{
    width: auto;
    margin-top: 0 !important;
    tr {
      height: 20px !important;
      td{
        border-bottom: none !important;
        padding: 0;
        height: 28px;
      }
    }
    .score_cell{
      width: 72px;
    }
    .score_cell_agent {
      width: 94px;
      text-align: center;
      > span {
        mask-image: url('../../../public/images/agent_status_icon.svg');
        background-color: #666;
        display: inline-block;        
        width: 14px;
        height: 14px;
        margin-right: 40px;
      }
    }
    .score_cell_cred {
      width: 94px;
      > span {        
        text-transform: uppercase;
        font-size: 9px;
        font-weight: bold;
        border: 0.8px solid #ededed;
        padding: 3.2px 4.8px;
        margin-right: 10px;
      }
    }
  }
    .v_block{
      display: inline-flex;
      justify-content: center;
      align-items: center;
      color: white;
      background-color: transparent;
      // border-width: 1px 3px;
      border-style: solid;
      border-width: 1px;
      // width: 26px;
      border-radius: 4px;
      padding: 1px 2px;
      // margin-right: 12px;
      &.critical {
        color: #f20c0c;
      }
      &.high {
        color: #ff6200;
      }
      &.medium {
        color: #f79009;
      }
      &.low {
        color: #12b76a;
      }
      &.none {
        color: #999;
      }
    }
  // }
}