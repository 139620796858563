.tfa_representation{
    display: flex;
    width: 100%;
    gap: 0 20px;
    justify-content: space-between;
    margin-bottom: 20px;
    .tfa_block{
        width: 240px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .tfa_add_data{
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #ccc;
            border-radius: 8px;
            text-align: center;
            height: 240px;
            width: 240px;
            overflow: hidden;
        }
        .tfa_secret_code{
            word-wrap: break-word;
            font-size: 24px;
            font-weight: bold;
            text-align: left;
        }
    }
}
.tfa_backup_codes{
    // background-color: #eee;
    border-radius: 5px;
    padding: 4px 0;
    ul{
        display: flex;
        flex-wrap: wrap;
        li{
            width: 50%;
            font-weight: bold;
            padding: 4px 0;
        }
    }
}