.check_credential {
  margin: 24px 0 !important;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 16px;
  > span {
    margin-bottom: 8px;
  }
}
.titleCheckCredential{
  margin-bottom: 16px;
}

.checkCredentialInfo {
  margin: 36px 0 0 0 !important;
  :global(.error-common-field) {
    margin: -4px 0 20px 0 !important;
  }
  :global(.check-credential-content) {
    margin: -16px 0 20px 0 !important;
  }
}
.assetImportanceWrapper{
  margin-top: 16px;
}

.asset_form {
  display: flex;
  .ip_error {
    color: #e92e2e;
    display: block;
    height: 20px;
  }
  .domain_info{
    color: #666666;
    display: block;
    height: 20px;
  }
}

.btn_block {
  display: flex;
  align-items: center;
}

.hostParamForm{
  margin: 0 0 24px 0 !important;
  padding-left: 16px;
  display: flex;
  align-items: center;
  :global(.textarea-field) {
    width: 260px;
  }
  .hostImportanceWrapper{
    margin: 0 12px 0 24px; 
  }
  .checkbox{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 300px;
    padding-top: 24px;  
  }
  .questionWrapper{
    display: inline-block;
    max-width: 16px;
    cursor: pointer;
  }
}

.assetParamForm{
  margin: 0 0 24px 0 !important;
  // padding-left: 16px;
  // width: 100%;
  display: flex;
  align-items: center;
  :global(.textarea-field) {
    width: 260px;
  }
  .hostImportanceWrapper{
    margin: 0 12px 0 24px; 
  }
  .checkbox{
    display: flex;
    // justify-content: space-evenly;
    align-items: center;
    width: 300px;
    padding-top: 40px;  
    margin-left: 32px;
  }
  .questionWrapper{
    display: inline-block;
    max-width: 16px;
    cursor: pointer;
    margin-left: 12px;
  }
}
.inputWrap{
  margin-bottom: 16px;
}
.realmInfo{
  display: flex;
  max-height: 64px;
  .realmInfoItem{
    margin-left: 12px;
  }
}

.authBlock{
  margin-top: 24px !important;
}

.errorCheckResult{
  color: #ef664f;
  // margin: -24px 0 0 0;
}
.resultCheckIp{
  color: #ef664f;
  // margin: -16px 0 16px 0;
}

.diff_form{
  height: auto !important;
}

.closed{
  // color: red;
  // background: red;
  background: rgba(255, 0, 0, 0.15) !important;
  
  // background: #d2d2d2;
}

.updated{
  // color: red;
  // background: green;
  background: rgba(0, 180, 0, 0.1) !important;
  // background: #fecc0955;
}

.rangeReg{
  width: 300px;
  box-sizing: border-box;
  &.audit_info{
    padding: 0 8px;
  }
}

.groupping{
  border-left: 1px solid #333 !important;
}

.diff_legend{
  display: flex;
  flex-direction: column;
  gap: 8px 0;
  margin-top: 8px;
  div{
    display: flex;
    gap: 8px;
  }
  .legend{
    border: 1px solid #ccc;
    width: 20px;
    height: 18px;
    display: inline-block;
    text-align: center;
    border-radius: 5px;
    vertical-align: middle;
  }
  // > span{
  //   display: flex;
  //   align-items: center;
  //   gap: 0 8px;
  // }
}

.ipHostLabelWrap{
  display: flex;
}
.resultCheckIpWrap{
  display: flex;
  margin-top: 8px; 
}
.resultCheckIpWarning{
  display: inline-block;
  align-items: center;
  margin-right: 4px;
  color: #a7a69f;
}

:global(.k-input-inner) {
  max-height: 100px;
  overflow-y: auto;
}

// .k-window{
//   width: 100%;
//   max-width: 1400px;
//   max-height: 80%;
// }

// .create-windows-asset{
//   // &__header{
//   //   background-color: hsla(0, 12%, 93%, 0.459);
//   //   padding: 4px 0 8px 96px;
//   //   max-width: 100%;
//   //   ul{
//   //     margin: 12px 0;
//   //   }
//   //   a{
//   //     color: inherit;
//   //   }
//   //   border-bottom: 1px solid #ccc;
//   // }
//   &__content{
//     width: 100%;

//     .asset-form{
//       // padding: 16px 0 0 32px;
//       // max-width: 680px;
//       display: flex;
//       justify-content: left;
//       .k-dropdownlist{
//         width: 252px;
//       }
//       .k-step-first{
//         margin: 6px 0 0 0;
//       }
//       .k-step:nth-child(2){
//         margin: 24px 0 44px 0;
//       }
//       .k-step:nth-child(3){
//         margin: 0 0 68px 0;
//       }
//       .k-step-list-vertical ~ .k-progressbar {
//         height: 272px;
//         margin-top: 16px;
//       }
//       // .asset-form__content{
//       //   margin-left: 20px;
//       // }
//       .asset-form__download {
//         // width: 100%;
//         width: 500px;
//         margin-bottom: 20px;
//         .btn-download{
//           background: #ff6358;
//           color: white;
//           width: 252px;
//           padding: 12px 0;
//           &:hover{
//             background: #db483d;
//           }
//         }
//         p:last-child{
//           margin-top: 24px;
//         }
//       }
//     }
//   }
//   &__actions{
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     padding-bottom: 16px;
//     border-top: 1px solid #ccc;
//     background-color: hsla(0, 12%, 93%, 0.459);
//   }
//   .asset-form{
//     margin-top: 12px;
//   }
//   .form-content .k-tabstrip {
//     margin: 0 !important;
//   }
// }
// .create-linux-asset{
//   &__header{
//     margin: 10px 0 !important;
//   }
//   &__content{
//     width: 100%;
//     .asset-form{
//       // padding: 16px 0 0 32px;
//       // max-width: 680px;
//       display: flex;
//       justify-content: left;
//       .k-steper{
//         top: 76px;
//       }
//       .k-step-first{
//         margin: 14px 0 0 0;
//       }
//       .k-step:nth-child(2){
//         margin: 164px 0 0 0;
//       }
//       .k-step:nth-child(3){
//         margin: 64px 0 0 0;
//       }
//       .k-step-list-vertical ~ .k-progressbar {
//         height: 312px;
//         margin-top: 10px;
//       }

//       // .asset-form__content{
//       //   margin-left: 20px;
//       // }
//       .asset-form__download {
//         // width: 100%;
//         width: 500px;
//         .btn-download{
//           background: #ff6358;
//           color: white;
//           width: 252px;
//           padding: 12px 0;
//           &:hover{
//             background: #db483d;
//           }
//         }
//         .download-container{
//           width: 100%;
//           display: flex;
//           align-items: center;
//           border: 1px solid #ccc;
//           padding: 8px 4px;
//           // margin: 4px 0;
//           .btn-block{
//             position: relative;
//             margin-right: 2px;
//           }
//         }
//       }
//       .asset-form__rights {
//         .rights-container{
//           // width: 100%;
//           width: 500px;
//           display: flex;
//           justify-content: space-around;
//           align-items: center;
//           border: 1px solid #ccc;
//           padding: 8px 4px;
//           margin-bottom: 28px;
//           .text-container{
//             display: flex;
//             align-items: center;
//             width: 548px;
//           }
//           .btn-block{
//             position: relative;
//           }
//         }
//       }
//     }
//     // .k-state-selected{
//     //   height: 60% !important;
//     // }
//   }
//   &__credential{
//     margin-top: 24px;
//     .add-credential-content{
//       margin-top: 16px;
//       cursor: pointer;
//       max-width: 200px;
//       padding: 6px 8px 6px 12px;
//       margin-left: -8px;
//       &:hover {
//         background-color: rgb(248, 249, 249);
//       }
//       &__wrapper {
//         display: inline-block;
//         span:nth-child(1) {
//           margin-right: 4px;
//         }
//         border-bottom: 1px dashed black;
//       }
//     }
//     //.check-credential-ip{
//       // margin-top: 16px;
//       // cursor: pointer;
//       // max-width: 200px;
//       // padding: 6px 8px 6px 12px;
//       // margin-left: -8px;
//       // &:hover {
//       //   background-color: rgb(248, 249, 249);
//       // }
//       // &__wrapper {
//       //   display: inline-block;
//       //   span:nth-child(1) {
//       //     margin-right: 4px;
//       //   }
//       //   // border-bottom: 1px dashed black;
//       // }
//     //}
//   }
//   &__actions{
//     width: 100%;
//     display: flex;
//     justify-content: center;
//     padding-bottom: 16px;
//     border-top: 1px solid #ccc;
//     background-color: hsla(0, 12%, 93%, 0.459);
//   }
//   .asset-form{
//     margin-top: 4px;
//   }
//   .form-content .k-tabstrip {
//     margin: 0 !important;
//   }
// }
// .create-linux-asset, .create-windows-asset{
//   padding: 0;
//   margin: 0;
//   // max-height: 720px;
//   position: relative;
//   table{
//     margin-top: 20px;
//     th{
//       color: #7f7f7f;
//     }
//     th:nth-child(2){
//       white-space: nowrap;
//     }
//     td{
//       text-align: center;
//       padding: 0 8px;
//     }
//     td:nth-child(2){
//       max-width: 200px;
//       overflow: hidden;
//       text-overflow: ellipsis;
//       white-space: nowrap;
//     }
//     td:nth-child(3){
//       width: 300px;
//     }
//   }
//   .add-delete-credential{
//     display: flex;
//     justify-content: space-between;
//   }
//   .check-credential-content{
//     margin-top: 16px;
//     display: flex;
//     align-items: center;
//     .check-credential-success{
//       margin-left: 12px;
//       &__content{
//         display: flex;
//         align-items: center;
//         span:nth-child(2){
//           margin-left: 8px;
//         }
//       }
//     }
//   }
//   .check-credential-false-wrapper{
//     margin-top: 16px;
//     .check-credential-false-description{
//       font-style: italic;
//       color: #e92e2e;
//       font-size: 12px;
//     }
//   }
//   .delete-credential{
//     span{
//       cursor: pointer;
//     }
//   }
//   .result-check-ip{
//     display: inline-block;
//     margin: 8px 0 0 0;
//     color: #e92e2e;
//   }
// }
