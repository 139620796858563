// @import "./src/vars";
// .security-description{
//   @extend %content-page;
//   .k-button-group {
//     display: none;
//   }
//   .k-widget .k-toolbar {
//     span:nth-child(3) button {
//       display: none;
//     }
//   }
//   .security-vulns{
//     .k-button-link{
//       .k-button-text{
//         border-bottom: 1px dashed black !important;
//         line-height: 0.8rem;
//       }
//     }
//     .k-button-link:hover{
//       text-decoration: none;
//     }
//     // .k-grid table{
//     // width: auto;
//     // }
//     th:nth-child(1), th:nth-child(3), th:nth-child(4), th:nth-child(5), th:nth-child(6){
//     .k-link{
//     cursor: default;
//     }
//     }
//     td:nth-child(1){
//     max-width: 90px;
//     }
//     td:nth-child(2), td:nth-child(3){
//     max-width: 180px;
//     }
//     td:nth-child(4){
//     max-width: 420px;
//     }
//     .search-input{
//     width: 25%;
//     margin: 0 0 20px 40px;
//     }
//   }
// }
.security-vulns{
  .whitelist-grid-title{
    margin-bottom: 4px;
    // font-style: italic;
    img{
      height: 16px;
      width: 16px;
    }
    color: #666666;
    display: flex;
    align-items: center;
    justify-content: center;
    button{
      padding-left: 4px;
      font-style: inherit;
      color: #333 !important;
    }
    &__text{
      display: inline-block;
      margin-right: 4px;
    }
  }
}
.security-vulns-wl{
  // margin-top: 16px;
  padding: 8px;
  background-color: #f9f9f9;
  border-radius: 8px;
  .k-grid, .filter-line, .k-widget, .k-button  {
    background-color: inherit;
  }
  &__title{
    margin-bottom: 12px;
    // font-style: italic;
    color: #666666;
    display: flex;
    justify-content: center;
    align-items: center;
    button{
      padding-left: 4px;
      font-style: inherit;
      color: #333 !important;
    }
  }

}
