.tfa_cell{
    display: flex;
    align-items: center;
    gap: 4px;
    .tfa_span{
        border: 1px solid #ccc;
        padding: 0 2px;
        font-size: 10px;
        border-radius: 4px;
        margin-right: 4px;
    }
    .value_span{
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.add_credential {
  padding: 0;
}

.serverMetablockWrap{
  padding: 16px 24px;
  border: 1px solid #ccc;
  border-radius: 5px;    
  margin-bottom: 24px;
}

.serverMetablock{
  display: flex;
  grid-gap: 0 60px;
  flex-wrap: wrap;
  align-items: baseline;
}
.serverMetablockDesc{
  word-break: break-all;
  .serverMetablockTitle{
    color: #333;
  }
  .serverMetablockContent{
    color: #666;
    padding-left: 8px;
  }
}


// .userListForServer{
//   table{
//     width: 100% !important;
//   }
// }

// @import "../../../vars";
// .users {
//     @extend %content-page;
// }

// .k-tabstrip {
//     @extend %tabstrip;
//     >.k-tabstrip-items-wrapper .k-item.k-state-active {
//         border-bottom-color: unset;
//     }
// }

// .k-grid>* {
//     margin-left: 0 !important;
//     .dropdown-menu {
//         overflow: visible;
//     }
//     .k-button {
//         background: inherit;
//         border: none;
//         border-radius: 0;
//     }
// }
