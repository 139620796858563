.tagBlock{
    display: flex;
    align-items: flex-start;
    margin-left: 16px;
    margin-bottom: 12px;
    font-size: 10px;
    height: auto;
    flex: none;
    max-width: 1000px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    flex-wrap: wrap;
    .tagActionsWrap{
      margin-bottom: 4px;
    }
  }
  .tagBlockHostList{
    display: flex;
    align-items: flex-start;
    font-size: 10px;
    align-items: center;
    height: auto;
    flex: none;
    // z-index: 0 !important;
    position: relative;
    overflow: hidden;
    // text-overflow: ellipsis;
    flex-wrap: wrap;
    .tagContentWrap{
      margin-top: 4px;
      margin-left: 8px;
      padding: 2px 6px 2px 4px !important;
      min-width: 28px !important;
    }
    .hoveredTagActionBlock{
      top: 5px !important;    
    }
    .tagActionsWrap{
      margin-bottom: 4px;
      max-width: 144px !important;
    }
    .tagsCount{
      border-radius: 50%;
      font-size: 10px;
      font-weight: 500;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #cbc9cf;
      cursor: pointer;
      // border-radius: 9px;
      // padding-inline: 2px;
      padding: 5px;
      // height: 18px;
      // flex-shrink: 0;
      // white-space: nowrap;
    }
  }
  .addTag{
    overflow: auto;
    background: white;
    width: 348px;
    position: absolute;
    z-Index: 999;
    font-size: 10px;
    border-radius: 8px;
    border: 1px solid #ccc;
    .tagContentWrap{
      margin-bottom: 8px;
      padding: 4px 8px 2px 6px !important;
    }
  }
  .addTagListWrap{
    font-size: 10px;  
    display: flex;
    flex-wrap: wrap;
    width: 348px;
    white-space: pre-line;
    min-height: 20px;
    padding: 12px 8px 4px 8px;
    .tagContentWrap{
      padding: 4px 8px 4px 6px !important;
    }
  }
  .addAllTags{
    background: white;
    max-height: 300px;
    overflow: auto;
    button{
      justify-content: start !important;
    }
    button:not(:last-child){
      border-bottom: 1px solid #ccc !important;
    }
    .tagContentWrap{
      margin: 4px 0 !important;
      padding: 4px 8px;
      border-radius: 0px 24px 24px 0;
      min-width: 40px;
    }
  }
  .tagActionsWrap{
    position: relative;
    flex: none;
    // max-width: 240px;
    // overflow: hidden;
    // text-overflow: ellipsis;
    // white-space: nowrap;
    .tagContentWrap{
      padding: 4px 8px 4px 0px;
      // border: 1px solid #ccc;
      border-radius: 0px 24px 24px 0;
      // overflow: hidden;
      // text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
      min-width: 60px;
      display: flex;
      align-items: center;
      justify-content: start;
      .tagImg{
        mask-image: url('../../../../public/images/tag.svg');
        mask-size: 100% 100%;
        min-width: 12px;
        min-height: 12px;
        margin: 0 4px 0 6px;
        padding: 0;
      }
      margin-right: 4px;
    }
    .hoveredTagActionBlock{
      display: flex;
      position: absolute;
      justify-content: space-between;
      top: 0px;
      right: 9px;
      background-color: #fff;
      padding: 0 4px;
      width: 100%;
      max-width: 32px;
      border-radius: 9px;
    }
    .hoveredTagActionBlockAdd{
      display: flex;
      position: absolute;
      justify-content: flex-end; 
      top: 0px;
      right: 10px;
      background-color: #fff;
      padding: 0px 0px 0px 12px;
      width: 100%;
      max-width: 8px;
      border-radius: 9px;
    }
  }
  .assetTagContextMenu{
    position: absolute;
    top: 28px;
    left: 52px;
    background-color: #fff;
    z-index: 999;
    border: 1px solid #ccc;
    width: 140px;
    border-radius: 4px;  
    button{
      padding: 8px !important;
      margin: 0 !important;
      // width: 100%;
      font-size: 10px !important;
      justify-content: start;
      &:last-child{
        border-top: 1px solid #ccc;
      }
    }
  }
  .assetInlistTagContextMenu{
    left: 152px !important;
  
  }
  .confirmContent{
    display: flex;
    align-items: center;
    margin-bottom: -12px;
    p:first-child{
      margin-right: 4px;
    }
    p:last-child{
      margin-left: 4px;
    }
  }
  .errorFieldUnderTag{
    margin: 2px 0 0 8px;  
  }
  .commonTooltipWrap{
    cursor: pointer;
  }
  .editTagInputWrap{
    display: flex;
    flex-direction: column;
    margin: 0px;
    padding: 0px;
    position: relative;
    // .editAllTags{
      // background: white;
      // max-height: 300px;
      // overflow: auto;
      // button:not(:last-child){
      //   border-bottom: 1px solid #ccc !important;
      // }
      // position: absolute;
      // top: 24px;
      // width: 100%;
      // button{
      //   justify-content: start !important;
      // }
    // }  // overflow: hidden;
    // text-overflow: ellipsis;
    .editAllTags{
      z-index: 999;
      position: absolute;
      top: 24px;
      // width: 100%;
      // background: white;
      max-height: 300px;
      overflow: auto;
      .editTagBtn{
        width: 100%;
        height: 32px !important;
        border-radius: 0px !important;
        border: 0px !important;
        font-size: 10px !important;
        justify-content: start !important;
        padding-left: 8px;
        // background: #ededed;
        // &:hover{
        //   background: #ededed !important;
        // }
        // &:not(:last-child){
        //   border-bottom: 1px solid #ccc !important;
        // }
      }
      .tagContentWrap{
        margin: 4px 0 !important;
        padding: 4px 8px;
        border-radius: 0px 24px 24px 0;
      }
    }  
  }
  .editTagWrapContainer{
    display: relative;
  }
  .editTagColorWrap{
    position: absolute;
    width: 265px;
    top: 28px;
    right: 12px;
    margin-left: 2px;
    padding: 8px;
    border: 1px solid #ccc;
    border-bottom: 0px;
    border-radius: 4px;
    // border-bottom-left-radius: 0px;
    // border-bottom-right-radius: 0px;
    background-color: #ededed;
    z-index: 999;
    // box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.03), 0 4px 5px 0 rgba(0, 0, 0, 0.04);
    .editTagColorTitle{
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-bottom: 4px;
      font-size: 12px;
      span{
        margin-left: 6px;
        // text-transform: uppercase;
      }
      :global(.button-noborder){
        margin: 0 !important;
      }
    }
    .editTagColorContainer{
      display: flex;
      flex-wrap: wrap;
      z-index: 99;
      .editTagColorElement{
        margin: 4px;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        cursor: pointer;
      }
    }
    .editTagColorPicker{
      margin-top: 12px;
      position: relative;
      // :global(.k-colorgradient) {
      //   top: 380px !important;
      // }
    }
  }
  .tagImg{
    mask-image: url('../../../../public/images/tag.svg');
    mask-size: 100% 100%;
    width: 12px;
    height: 12px;
    margin-right: 4px;
  }
  .tagTitle{
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    white-space: nowrap;
  }
  .allTagBlockHostListWrap{
    position: absolute;
    z-Index: 999;
    // right: 1150px;
    background: #eeeff0;
    border: 1px solid #ccc;
    border-radius: 8px;
    max-height: 300px;
    overflow: auto;
    white-space: pre-line;
    padding: 8px;
    // padding: 8px 4px 8px 12px;
    min-width: 100px;
    width: auto;
    max-width: 440px;
    margin-top: 70px;
    margin-left: 160px;
    text-align: center;
    // font-size: 10px;
    // .allTagHostListActions{
    //   position: absolute;
    //   top: 6px;
    //   right: 4px;
    // }
    .allTagBlockHostList{
      display: flex;
      flex-wrap: wrap;
      margin-top: 8px;
      .tagActionsWrap{
        margin-bottom: 8px;
        max-width: 230px !important;
      }
      .tagContentWrap{
        padding: 2px 6px 2px 4px !important;
      }
      .hoveredTagActionBlock{
        top: 5px !important;    
      }
    }
  }