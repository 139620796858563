$white: #ffffff;
$closer-white: #ebebeb;
$black: #000000;
$light-green: #44c74f;
$green: #00850b;
$light-grey: #ccc;
$grey: #666;
$dark-grey: #333;
$orange: #ff7f11;
$lightest-grey: #ededed;

// $button-text-color: $orange;
// $table-text-color: $orange;
// $table-header-color: $orange;

// $button-text-color: $grey;
// $table-text-color: $grey;
$button-text-color: #424242;
$table-text-color: #424242;
$table-header-color: $dark-grey;

$border-color: $light-grey;
$menu-selected-color: $orange;
$text-green-color: $green;
$button-green-color: $light-green;
$main-text-color: $dark-grey;
$button-hover-color: $closer-white;

// $background-color: white;
// $split-table-color: #f2f2f2;
// $red-color: #ff6358;
// $pager-border-color: #d7d7d7;
// $pager-text-color: #aaaaaa;
// $border-color: #d7d7d7;
// $text-grey-color: #7f7f7f;
// $left-margin: 40px;
// $font-family: 'Arial Обычный', 'Arial', sans-serif;

.add-user-form {
  .k-content {
    margin-top: 0;
  }
}
